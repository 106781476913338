import React from 'react'

export class VideoErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, info: null }
  }

  static getDerivedStateFromError(error) {
    return { error: error }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    const {error} = this.state

    if (error) {
        return <>
            <p>There has been a problem loading the training video.</p>
            <p>Please check that Vimeo.com is not blocked by your workplace or local network operator.</p>
        </>
    } else {
      return <>{this.props.children}</>
    }
  }
}     
       